import React, { useEffect, useContext } from "react";
import { AuthContext } from "../contexts/auth";
import { OrderView, OrdersOverview, CompletedOrders } from "./orders";
import { Switch } from "react-router-dom";
import { logout } from "../services/auth";
import { OrderContext } from "../contexts/orders";
import { RouteWithSubRoutes } from "../resources/helpers";
import UnlinkedAccount from "./auth/UnlinkedAccount";
import Home from "./Home";
import Profile from "./profile/Profile";
import {
  subscribeToOrders,
  subscribeToAssignedOrders,
  initiateSocket,
  disconnectSocket,
  subscribeToCanceledOrders,
  subscribeToOrdersUpdates,
  fetchOrders,
  handleReconnection,
  handleDisconnection,
} from "../services/socket";
import { useInterval } from "../resources/hooks";
import { ORDERS_REFRESH_INTERVAL } from "../resources/constants";
import "../styles/DeliveryInterface.scss";

const routes = [
  {
    path: "/orders/completed",
    component: () => <CompletedOrders />,
  },
  {
    path: "/orders/:orderId",
    component: () => <OrderView />,
  },
  {
    path: "/orders",
    component: () => <OrdersOverview />,
  },
  {
    path: "/profile",
    component: () => <Profile />,
  },
  {
    path: "/",
    component: () => <Home />,
  },
];

const DeliveryInterface = () => {
  const {
    state: { currentUser },
    dispatch: authDispatch,
  } = useContext(AuthContext);

  const { dispatch: orderDispatch } = useContext(OrderContext);

  const refreshOrders = (deliverymanId) => {
    fetchOrders((err, { orders }) => {
      orderDispatch({ type: "REFRESH_ORDERS", orders });
    }, deliverymanId);
  };

  const { reset: resetRefreshInterval } = useInterval(
    () => refreshOrders(currentUser.id),
    ORDERS_REFRESH_INTERVAL * 1000
  );

  const disconnect = async () => {
    try {
      await logout();
      authDispatch({ type: "SET_USER", user: null });
    } catch (err) { }
  };

  useEffect(() => {
    initiateSocket(currentUser.id, (err, { delivery }) => {
      if (!delivery) console.log("Error while joining room");

      subscribeToOrders((err, { order }) => {
        if (err) return;
        resetRefreshInterval();
        if (order.delivered)
          orderDispatch({ type: "SET_SELECTED_ORDER", order: null });
        else orderDispatch({ type: "UPDATE_ORDER", order });
      });

      subscribeToAssignedOrders((err, { order }) => {
        if (err) return;
        resetRefreshInterval();
        if (order) orderDispatch({ type: "ADD_ACTIVE_ORDER", order });
      });

      subscribeToOrdersUpdates((err, { order, estimatedPickupAt }) => {
        if (err) return;
        resetRefreshInterval();
        if (order)
          orderDispatch({ type: "UPDATE_ORDER", order, estimatedPickupAt });
      });

      subscribeToCanceledOrders((err, { order }) => {
        if (err) return;
        resetRefreshInterval();
        if (order) orderDispatch({ type: "SET_CANCEL_ORDER", order });
      });

      handleDisconnection((reason) => {
        console.warn(`Disconnection from socket for reason [${reason}]`);
      });

      handleReconnection(
        { userId: currentUser.id },
        (reconnectionAttemptCount) => {
          console.warn("reconnected after", reconnectionAttemptCount, "attempts");

          refreshOrders(currentUser.id);
        }
      );

      return () => {
        disconnectSocket();
      };
    });
  }, []);

  return !currentUser.psid ? (
    <UnlinkedAccount currentUser={currentUser} disconnect={disconnect} />
  ) : (
    <div id="delivery-interface">
      <Switch>
        {routes.map((route, index) => (
          <RouteWithSubRoutes key={index} {...route} />
        ))}
      </Switch>
    </div>
  );
};

export default DeliveryInterface;
