import React, { Fragment } from "react";
import {
  RestaurantProducts,
  CustomerInformations,
  PaymentInformations,
} from "./";
import { Divider } from "../common";
import { getOrderTotal } from "../../resources/helpers/orderTotal";

const CompletedOrder = ({ order }) => {
  return (
    <div id="completed-order">
      {order.restaurantOrders.map((restaurantOrder) => (
        <Fragment key={restaurantOrder.restaurantId}>
          <RestaurantProducts
            withStatus
            restaurantId={restaurantOrder.restaurantId}
            restaurantLabel={restaurantOrder.label}
            status={restaurantOrder.status}
            products={order.orderProducts}
          />
        </Fragment>
      ))}

      <Divider />

      <CustomerInformations
        customer={order.customer}
        phone={order.phone}
        address={order.deliveryAddress}
        city={order.city}
        comments={order.comments}
      />

      <Divider />

      <PaymentInformations
        paymentMethod={order.paymentMethod}
        total={getOrderTotal(order)}
      />
    </div>
  );
};

export default CompletedOrder;
