import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import { Button, Form, Header } from "semantic-ui-react";
import { AuthContext } from "../../contexts/auth";
import { loginWithCredentials } from "../../services/auth";
import { useInput, useRequest } from "../../resources/hooks";
import "./LoginPage.css";
import logo from "../../resources/png/logo_funky_blanc.png";

const LoginPage = () => {
  const {
    state: { currentUser, isConnecting },
    dispatch,
  } = useContext(AuthContext);
  const email = useInput("");
  const password = useInput("", { type: "password" });
  const login = useRequest({
    service: loginWithCredentials,
    requestOnInit: false,
  });

  const handleLogin = async () => {
    dispatch({ type: "USER_CONNECT_LOADING" });

    const loginResult = await login.request({
      params: {
        email: email.value,
        password: password.value,
      },
    });

    if (loginResult.response) {
      dispatch({ type: "SET_USER", user: loginResult.response });
    }
    dispatch({ type: "USER_CONNECT_END" });
  };

  if (currentUser)
    return (
      <Redirect
        to={{
          pathname: "/",
        }}
      />
    );

  return (
    <div id="login-page">
      <img src={logo} alt="restos-bussy-logo" />
      <Header as="h3" inverted>
        Livreur
      </Header>
      <Form inverted>
        <Form.Field>
          <label>Utilisateur</label>
          <input {...email.props} />
        </Form.Field>
        <Form.Field>
          <label>Mot de passe</label>
          <input {...password.props} />
        </Form.Field>
        <Button onClick={handleLogin} loading={isConnecting} fluid>
          Se connecter
        </Button>
      </Form>
    </div>
  );
};

export default LoginPage;
