import React from "react";
import { useHistory } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import "../styles/home.scss";

const Home = () => {
  const history = useHistory();
  
  return (
    <div id="home" className="menu">
      <div className="menu-item" onClick={() => history.push("/orders")}>
        <Icon name="car" size="big" />
        <span>Commandes à livrer</span>
      </div>
      
      <div
        className="menu-item"
        onClick={() => history.push("/orders/completed")}
      >
        <Icon name="check" size="big" />
        <span>Commandes terminées</span>
      </div>
      
      <div className="menu-item" onClick={() => history.push("/profile")}>
        <Icon name="user" size="big" />
        <span>Profil</span>
      </div>
    </div>
  );
};

export default Home;
