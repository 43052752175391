import { getOrderStatus } from "../../resources/constants/orderStatus";

const OrderStatus = ({ status, inverted, large, style }) => {
  return (
    <div
      className={`order-status${large ? " large" : ""}`}
      style={{
        ...getOrderStatus(status)[inverted ? "invertedStyle" : "style"],
        ...style,
      }}
    >
      <span>{getOrderStatus(status).label}</span>
    </div>
  );
};

export default OrderStatus;
