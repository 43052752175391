import React, { useContext } from "react";
import { Button, Icon } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../contexts/auth";
import { logout } from "../../services/auth";
import "../../styles/Profile.scss";

const Profile = () => {
  const {
    state: { currentUser },
    dispatch: authDispatch,
  } = useContext(AuthContext);

  const history = useHistory();

  const disconnect = async () => {
    try {
      await logout();
      authDispatch({ type: "SET_USER", user: null });
    } catch (err) {}
  };

  return (
    <div id="profile">
      <div className="body">
        <h2>
          <span style={{ fontWeight: 100 }}>Hello</span>{" "}
          {currentUser?.firstname} {currentUser?.lastname}
        </h2>
      </div>
      <nav className="navigation">
        <Icon
          name="chevron left"
          style={{ color: "white" }}
          size="large"
          onClick={() => history.push("/")}
        />
        <Button onClick={disconnect} color="red">
          Déconnexion
        </Button>
      </nav>
    </div>
  );
};

export default Profile;
