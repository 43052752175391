import { useReducer, useEffect, createContext } from "react";

const byDeliveryHour = (a, b) =>
  a.restaurantOrders[0].estimatedPickupAt <
    b.restaurantOrders[0].estimatedPickupAt
    ? -1
    : 1;

let reducer = (state, action) => {
  switch (action.type) {
    case "SET_SELECTED_ORDER":
      if (!action.order)
        return {
          ...state,
          selectedOrder: null,
        };
      else if (action.order.id === state.orderId) {
        const selected =
          action.order ||
          state.activeOrders.find((order) => order.id === action.order.id);

        return {
          ...state,
          selectedOrder: selected,
        };
      } else {
        return {
          state, selectedOrder: action.order,
        };
      }
    case "UPDATE_ORDER":
      let prunedOrders = state.activeOrders.filter(
        (o) => o.id !== action.order.id
      );
      const updatedOrder = action.order;
      updatedOrder.isNew = true;
      prunedOrders = [...prunedOrders, updatedOrder];

      const selectedOrder =
        state.selectedOrder && state.selectedOrder.id === updatedOrder.id
          ? updatedOrder
          : state.selectedOrder;

      return {
        ...state,
        activeOrders: prunedOrders.sort(byDeliveryHour),
        selectedOrder,
      };

    case "REFRESH_ORDERS":
      return refreshOrders(state, action.orders);

    case "SET_ORDER_ID":
      return {
        ...state,
        orderId: action.orderId,
      };
    case "ORDER_LOADING":
      return {
        ...state,
        orderLoading: true,
      };
    case "ORDER_LOADING_END":
      return {
        ...state,
        orderLoading: false,
      };

    case "SET_ACTIVE_ORDERS":
      return {
        ...state,
        activeOrders: action.activeOrders
          .map((order) => ({
            ...order,
            isNew: !order.orderDelivery.deliverymanConfirm,
          }))
          .sort(byDeliveryHour),
      };
    case "ADD_ACTIVE_ORDER":
      const existingOrder = state.activeOrders.find(
        (order) => order.id === action.order.id
      );
      let activeOrders = [...state.activeOrders];
      if (existingOrder) {
        activeOrders = activeOrders.filter(
          (order) => order.id !== existingOrder.id
        );
        // TODO: here display "new informations" about order delivery
      }
      activeOrders = [...activeOrders, action.order].sort(byDeliveryHour);
      return {
        ...state,
        activeOrders,
      };
    case "SET_CANCEL_ORDER":
      const canceledOrder = state.activeOrders.find(
        (order) => order.id === action.order.id
      );
      if (!canceledOrder) {
        return state;
      }
      let orders = state.activeOrders.filter(
        (order) => order.id !== canceledOrder.id
      );
      canceledOrder.isCanceled = true;

      return {
        ...state,
        activeOrders: [...orders, canceledOrder].sort(byDeliveryHour),
      };
    default:
      return state;
  }
};

const refreshOrders = (state, refreshedOrders) => {
  const refreshedOrdersIds = refreshedOrders.map((order) => order.id);
  const currentOrdersIds = state.activeOrders.map((order) => order.id);

  const canceledOrders = state.activeOrders.reduce((acc, order) => {
    if (!refreshedOrdersIds.includes(order.id)) {
      return [...acc, { ...order, isCanceled: true }];
    }
    return acc;
  }, []);

  let orders = refreshedOrders.reduce((acc, order) => {
    // Updated orders
    if (currentOrdersIds.includes(order.id)) {
      const orderToCompare = state.activeOrders.find(
        ({ id }) => id === order.id
      );

      if (orderToCompare.estimatedPickupAt !== order.estimatedPickupAt) {
        return [...acc, { ...order, isNew: order.orderDelivery.deliverymanConfirm }];
      }
      return [...acc, orderToCompare];
    }

    // New orders
    if (!currentOrdersIds.includes(order.id)) {
      return [...acc, { ...order, isNew: order.orderDelivery.deliverymanConfirm }];
    }

    return acc;
  }, []);

  return {
    ...state,
    activeOrders: [...orders, ...canceledOrders].sort(byDeliveryHour),
  };
};

const initialState = {
  selectedOrder: null,
  orderId: null,
  activeOrders: [],
  orderLoading: true,
};

const OrderContext = createContext(initialState);

function OrderProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => { }, []);

  return (
    <OrderContext.Provider value={{ state, dispatch }}>
      {props.children}
    </OrderContext.Provider>
  );
}
export { OrderContext, OrderProvider };
