export const getOrderStatus = (status) => {
  switch (status) {
    case "CONFIRMED":
      return orderStatus.CONFIRMED;
    case "ASSIGNED":
      return orderStatus.ASSIGNED;
    case "PREPARATION":
      return orderStatus.PREPARATION;
    case "READY":
      return orderStatus.READY;
    case "DELIVERING":
      return orderStatus.DELIVERING;
    case "DELIVERED":
      return orderStatus.DELIVERED;
    case "CANCELED":
      return orderStatus.CANCELED;
    default:
      return orderStatus.UNKNOWN;
  }
};

export const orderStatus = {
  CONFIRMED: {
    label: "A dispatcher",
    state: "warning",
    code: "CONFIRMED",
    emote: "⏳",
    style: {
      color: "white",
      border: "none",
      backgroundColor: "#f5bd1f",
    },
    invertedStyle: {
      color: "#f5bd1f",
      borderColor: "#f5bd1f",
    },
  },
  ASSIGNED: {
    label: "En attente",
    state: "warning",
    code: "ASSIGNED",
    emote: "⏳",
    style: {
      color: "white",
      border: "none",
      backgroundColor: "#bdbdbd",
    },
    invertedStyle: {
      color: "#e3e3e3",
      borderColor: "#e3e3e3",
    },
  },
  PREPARATION: {
    label: "En préparation",
    state: "warning",
    code: "PREPARATION",
    emote: "🍳",
    style: {
      color: "white",
      border: "none",
      backgroundColor: "#f5bd1f",
    },
    invertedStyle: {
      color: "#f5bd1f",
      borderColor: "#f5bd1f",
    },
  },
  READY: {
    label: "Prête",
    state: "valid",
    code: "READY",
    emote: "✅",
    style: {
      color: "white",
      border: "none",
      backgroundColor: "#67bc75",
    },
    invertedStyle: {
      color: "#67bc75",
      borderColor: "#67bc75",
    },
  },
  DELIVERING: {
    label: "En livraison",
    state: "passive",
    code: "DELIVERING",
    emote: "🚙",
    style: {
      color: "white",
      border: "none",
      backgroundColor: "#bdbdbd",
    },
    invertedStyle: {
      color: "#e3e3e3",
      borderColor: "#e3e3e3",
    },
  },
  DELIVERED: {
    label: "Livré",
    state: "valid",
    code: "DELIVERED",
    emote: "✅",
    style: {
      color: "white",
      border: "none",
      backgroundColor: "#67bc75",
    },
    invertedStyle: {
      color: "#67bc75",
      borderColor: "#67bc75",
    },
  },
  CANCELED: {
    label: "Annulée",
    state: "error",
    code: "CANCELED",
    emote: "❌",
    style: {
      color: "white",
      border: "none",
      backgroundColor: "#bc6767",
    },
    invertedStyle: {
      color: "#bc6767",
      borderColor: "#bc6767",
    },
  },
  UNKNOWN: {
    label: "?",
    state: "passive",
    code: "UNKNOWN",
    style: {
      color: "white",
      border: "none",
      backgroundColor: "#bdbdbd",
    },
    invertedStyle: {
      color: "#e3e3e3",
      borderColor: "#e3e3e3",
    },
  },
};
