import { Column, Price, Row } from ".";
import { PriceHelper } from "../../resources/helpers";
import { orderTotal } from "../../resources/helpers";

const OrderProducts = ({ products }) => {
  return (
    <div className="restaurant-products">
      <Column className="restaurant">
        {products.map((product) => (
          <ProductRow key={product.id} product={product} />
        ))}
      </Column>
    </div>
  );
};

const ProductRow = ({ product }) => {
  return (
    <Column
      className={`product${product.status === "canceled" ? " canceled" : ""}`}
    >
      <Row align="center" childrenMargin="tiny">
        <div className="count">{product.quantity}</div>

        <div className="name">{product.label}</div>

        <Row className="price-wrapper" align="center">
          <div className={`item-price`}>
            <Price fontSize="1.3">
              {PriceHelper.renderCentimesAsAmount(
                product.price +
                  orderTotal.getProductOptionsTotal(product.orderProductOptions)
              )}
            </Price>
          </div>
        </Row>
      </Row>

      <ProductSpecifics
        options={product.orderProductOptions}
        comments={product.comments}
      />
    </Column>
  );
};

const ProductSpecifics = ({ options, comments }) => {
  if (options?.length === 0 && !comments) return null;

  return (
    <Column className="item-additionnal-information">
      {options && (
        <Column className="item-options">
          {options.map((option) => (
            <span key={option.id}>
              {option.option}:{" "}
              <span className="option-choices">{option.choice}</span>
            </span>
          ))}
        </Column>
      )}

      {comments && <span className="comments">{`"${comments}"`}</span>}
    </Column>
  );
};

export default OrderProducts;
