import { Button, Header, Icon } from "semantic-ui-react";
import { Column, Row } from "../common";
import { ReactComponent as UserIcon } from "../../resources/svg/user.svg";
import { ReactComponent as PhoneIcon } from "../../resources/svg/phone.svg";
import { ReactComponent as LocationIcon } from "../../resources/svg/location.svg";
import { ReactComponent as InfoIcon } from "../../resources/svg/info.svg";

const CustomerInformations = ({ customer, phone, address, city, comments }) => {
  return (
    <Column className="customer-information">
      <Row>
        <Header style={{ margin: 0 }}>Informations client</Header>
      </Row>

      <Row margin="5px 0" align="center">
        <UserIcon className="svg-icon" />
        <span>{`${customer.firstname} ${customer.lastname}`}</span>
      </Row>
      <Row margin="5px 0" align="center">
        <PhoneIcon className="svg-icon" />
        <span>{phone}</span>
        <a href={`tel:${phone}`} style={{ marginLeft: "auto" }}>
          <Button icon>
            <Icon name="phone" rotated="clockwise" />
          </Button>
        </a>
      </Row>
      <Row margin="5px 0" align="center">
        <LocationIcon className="svg-icon" />
        <Column>
          <span>{address}</span>
          <span>{city}</span>
        </Column>
        <a
          href={`https://maps.google.com/?q=${address} ${city}`}
          style={{ marginLeft: "auto" }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button
            icon="map marker alternate"
            // style={{ marginLeft: "auto" }}
          />
        </a>
      </Row>
      {comments && (
        <Row margin="5px 0" align="center">
          <InfoIcon className="svg-icon" />
          <span>{comments}</span>
        </Row>
      )}
    </Column>
  );
};

export default CustomerInformations;