import { getPaymentMethod } from "../../resources/constants/paymentMethods";

const PaymentBadge = ({ paymentMethod, style, large, small, inverted }) => {
  return (
    <div
      className={`payment-status${large ? " large" : ""}${
        small ? " small" : ""
      }`}
      style={{
        ...getPaymentMethod(paymentMethod)?.[
          inverted ? "invertedStyle" : "style"
        ],
        ...style,
      }}
    >
      <span>{getPaymentMethod(paymentMethod).label}</span>
    </div>
  );
};

export default PaymentBadge;
