const formatTime = (date) =>
  date ? (
    new Intl.DateTimeFormat("fr-FR", {
      hour: "2-digit",
      minute: "2-digit",
    }).format(new Date(date))
  ) : (
    <span style={{ opacity: 0.5 }}>-</span>
  );

export default formatTime;
