import React, { useContext } from "react";
import { ToastContainer } from "react-toastify";
import "./App.css";
import { AuthContext, AuthProvider } from "./contexts/auth";
import { OrderProvider } from "./contexts/orders";
import {
  Route,
  Switch,
  Redirect,
  BrowserRouter as Router,
} from "react-router-dom";
import LoginPage from "./components/auth/LoginPage";
import DeliveryInterface from "./components/DeliveryInterface";
import "semantic-ui-css/semantic.min.css";

function App() {
  return (
    <Router>
      <AuthProvider>
        <WithAutoConnect />
      </AuthProvider>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        closeOnClick
        rtl={false}
        draggable
        pauseOnHover
      />
    </Router>
  );
}

const WithAutoConnect = () => {
  const {
    state: { isAutoConnecting },
  } = useContext(AuthContext);

  if (isAutoConnecting) {
    return "Chargement ...";
  }

  return (
    <Switch>
      <Route exact path="/login">
        <LoginPage />
      </Route>

      <PrivateRoute path="/">
        <OrderProvider>
          <DeliveryInterface />
        </OrderProvider>
      </PrivateRoute>
    </Switch>
  );
};

const PrivateRoute = ({ children, ...rest }) => {
  const {
    state: { currentUser },
  } = useContext(AuthContext);

  return !currentUser ? (
    <Redirect
      to={{
        pathname: "/login",
      }}
    />
  ) : (
    <Route {...rest} render={() => children} />
  );
};

export default App;
