import { useContext, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import { OrderContext } from "../../contexts/orders";
import { useRequest } from "../../resources/hooks";
import { fetchOrders } from "../../services/orders";
import { getOrderStatus } from "../../resources/constants/orderStatus";
import { Column, Row, WithLoader, Divider, NoData, Ping } from "../common";
import PickupTime from "./PickupTime";

const OrdersOverview = ({ history }) => {
  const {
    state: { activeOrders },
    dispatch,
  } = useContext(OrderContext);

  const getOrders = useRequest({ service: fetchOrders });

  useEffect(() => {
    dispatch({
      type: "SET_ACTIVE_ORDERS",
      activeOrders: getOrders.response ?? [],
    });
  }, [getOrders.response]);

  return (
    <div id="orders-overview">
      <div className="header">
        <Icon
          name="arrow left"
          onClick={() => {
            history.push("/");
          }}
        />
        <span>Commandes en cours</span>
      </div>
      <WithLoader loading={getOrders.loading} text="Récupération des commandes">
        {activeOrders.length > 0 ? (
          activeOrders.map((order) => (
            <div
              key={order.id}
              onClick={() => history.push(`orders/${order.id}`)}
            >
              <Row
                className={`order-row${order.isCanceled ? " canceled" : ""}`}
                childrenMargin="small"
                align="center"
              >
                <Row className="number" justify="center" align="center">
                  {order.isNew && <Ping />}
                  {order.number}
                </Row>

                <Column style={{ flexBasis: "60%", overflow: "hidden" }}>
                  <span className="customer">{`${order.deliveryAddress.split(",")[0]
                    }`}</span>
                  <span className="customer">{`${order.deliveryAddress.split(",")[1]
                    }`}</span>
                  {order.restaurantOrders.map(
                    (restaurantOrder, i) =>
                      i < 6 && (
                        <span
                          key={restaurantOrder.id}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          {getOrderStatus(restaurantOrder.status).emote}{" "}
                          <span
                            style={{
                              opacity: 0.4,
                              fontSize: "0.9rem",
                              fontWeight: 900,
                            }}
                          >
                            {restaurantOrder.label.length > 20
                              ? `${restaurantOrder.label.substring(0, 20)}...`
                              : restaurantOrder.label}
                          </span>
                        </span>
                      )
                  )}
                </Column>
                {order.isCanceled && (
                  <span className="cancel-label">Annulée</span>
                )}

                <PickupTime order={order} isCanceled={order.isCanceled} />
              </Row>
              <Divider noMargin />
            </div>
          ))
        ) : (
          <NoData text="Pas de commande en cours" centered />
        )}
      </WithLoader>
    </div>
  );
};

export default withRouter(OrdersOverview);
