import { Fragment } from "react";
import { Button, Message } from "semantic-ui-react";
import { Column, Row, Divider } from "../common";
import {
  getPaymentMethod,
  paymentMethods,
} from "../../resources/constants/paymentMethods";
import { orderStatus } from "../../resources/constants/orderStatus";
import {
  CustomerInformations,
  PaymentInformations,
  RestaurantProducts,
  DeliveryInformations,
} from "./";
import { PriceHelper } from "../../resources/helpers";
import { getOrderTotal } from "../../resources/helpers/orderTotal";

const Order = ({
  order,
  handleConfirmDelivery,
  deliveryLoading,
  pickupLoading,
  handlePickup,
}) => {
  const notOnlyCanceledStatus = !order.restaurantOrders.every(
    ({ status }) => status === orderStatus.CANCELED.code
  );
  const allHaveDeliverableState = order.restaurantOrders.every(
    ({ status }) =>
      status === orderStatus.DELIVERING.code ||
      status === orderStatus.CANCELED.code
  );

  return (
    <div className="content">
      {notOnlyCanceledStatus && allHaveDeliverableState && (
        <>
          <Row className="confirm-delivery">
            <Button
              color="green"
              fluid
              size="big"
              loading={deliveryLoading}
              disabled={deliveryLoading}
              onClick={handleConfirmDelivery}
            >
              Confirmer la remise au client
            </Button>
          </Row>

          {!order.isPaid &&
            getPaymentMethod(order.paymentMethod).code !== "card_online" && (
              <Row className="delivery-warning">
                <Message
                  warning
                  icon="warning sign"
                  size="large"
                  header="Paiement"
                  content={
                    getPaymentMethod(order.paymentMethod).code ===
                    paymentMethods.cash.code
                      ? `💶 Encaisser ${PriceHelper.renderCentimesAsAmount(
                          getOrderTotal(order)
                        )} en espèces !`
                      : getPaymentMethod(order.paymentMethod).code ===
                        paymentMethods.card_manual.code
                      ? `💳 Encaisser ${PriceHelper.renderCentimesAsAmount(
                          getOrderTotal(order)
                        )} en CB !`
                      : getPaymentMethod(order.paymentMethod).code ===
                        paymentMethods.manual.code
                      ? `💰 Encaisser le client pour ${PriceHelper.renderCentimesAsAmount(
                          getOrderTotal(order)
                        )}`
                      : ""
                  }
                />
              </Row>
            )}

          <Divider />
        </>
      )}
      <Column>
        {order.restaurantOrders.map((restaurantOrder) => (
          <Fragment key={restaurantOrder.restaurantId}>
            <RestaurantProducts
              withStatus
              restaurantId={restaurantOrder.restaurantId}
              restaurantLabel={restaurantOrder.label}
              status={restaurantOrder.status}
              estimatedPickupAt={restaurantOrder.estimatedPickupAt}
              pickupAt={restaurantOrder.pickupAt}
              products={order.orderProducts}
              pickupLoading={pickupLoading}
              handlePickup={handlePickup}
            />
          </Fragment>
        ))}

        <Divider noMargin />

        <DeliveryInformations
          estimatedPickupAt={order.restaurantOrders[0].estimatedPickupAt}
          estimatedDeliveryAt={order.orderDelivery.estimatedDeliveryAt}
          status={order.restaurantOrders[0].status}
          comments={order.comments}
        />

        <Divider noMargin />

        <CustomerInformations
          customer={order.customer}
          phone={order.phone}
          address={order.deliveryAddress}
          city={order.city}
        />

        <Divider noMargin />

        <PaymentInformations
          paymentMethod={order.paymentMethod}
          total={getOrderTotal(order)}
        />
      </Column>
    </div>
  );
};

export default Order;
