import axios from "axios";
axios.defaults.withCredentials = true;
axios.defaults.baseURL =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_LOCAL_API_URL
    : process.env.REACT_APP_API_URL;

export async function loginWithToken() {
  try {
    const { data } = await axios.post("/api/auth/delivery/login/token");

    return data;
  } catch (err) {
    return null;
  }
}

export async function loginWithCredentials({ email, password }) {
  const { data } = await axios.post("/api/auth/delivery/login", {
    email,
    password,
  });

  return data;
}

export async function getSession() {
  const { data } = await axios.get("/api/auth/delivery/session");

  return data;
}

export async function logout() {
  const { data } = await axios.post("/api/auth/delivery/disconnect");

  return data;
}

export async function disconnect() {
  try {
    const { data } = await axios.post(
      "/api/auth/delivery/disconnect?origin=delivery"
    );

    return data;
  } catch (err) {
    return null;
  }
}

export async function getVerificationCode() {
  const { data } = await axios.get("/api/delivery/verificationCode");

  return data;
}
