import { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Button, Icon } from "semantic-ui-react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { OrderContext } from "../../contexts/orders";
import { AuthContext } from "../../contexts/auth";
import * as OrderService from "../../services/orders";
import {
  pickupOrder,
  confirmOrder,
  confirmDelivery,
} from "../../services/socket";
import { Row, WithLoader, NoData } from "../common";
import { useRequest } from "../../resources/hooks";
import { CompletedOrder, Order } from "./";

const OrderView = () => {
  const {
    state: { currentUser },
  } = useContext(AuthContext);

  const {
    state: { selectedOrder },
    dispatch,
  } = useContext(OrderContext);

  const { orderId } = useParams();
  const history = useHistory();
  const [isDelivered, setIsDelivered] = useState(false);
  const [deliveredId, setDeliveredId] = useState(null);

  const [pickupLoading, setPickupLoading] = useState(false);
  const [deliveryLoading, setDeliveryLoading] = useState(false);

  const getOrder = useRequest({
    service: () => OrderService.fetchOrderById(orderId),
  });

  const pickupRestaurantOrder = useRequest({
    service: OrderService.pickupRestaurantOrder,
    requestOnInit: false,
  });

  useEffect(() => {
    if (getOrder.response) {
      dispatch({ type: "SET_ORDER_ID", orderId });
      dispatch({ type: "SET_SELECTED_ORDER", order: getOrder.response });
      if (!getOrder.response.deliverymanConfirm) {
        confirmOrder(
          { orderId, deliverymanId: currentUser.id },
          (err, { confirm }) => {}
          // if (confirm)
          //   toast.success("Commande confirmée pour prise en charge 👌", {
          //     position: "top-right",
          //     autoClose: 3000,
          //     hideProgressBar: false,
          //     closeOnClick: true,
          //     progress: undefined,
          //   });
          // }
        );
      }
    }
  }, [getOrder.response]);

  const handleConfirmDelivery = async () => {
    setDeliveryLoading(true);
    confirmDelivery(
      { orderId, deliverymanId: currentUser.id },
      (err, { delivered }) => {
        if (delivered) {
          setIsDelivered(true);
          setDeliveredId(orderId);
          dispatch({ type: "SET_SELECTED_ORDER", order: null });
          dispatch({ type: "SET_ORDER_ID", orderId: null });
        }
      }
    );
  };

  const handlePickup = async (restaurantId) => {
    setPickupLoading(true);
    const pickupResult = await pickupRestaurantOrder.request({
      params: {
        orderId,
        restaurantId,
      },
    });

    if (pickupResult.response) {
      dispatch({
        type: "SET_SELECTED_ORDER",
        order: pickupResult.response.order,
      });
    } else {
      toast.error("Erreur lors de la prise en charge de la commande", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        progress: undefined,
      });
    }
    setPickupLoading(false);
    // pickupOrder(
    //   { orderId, deliverymanId: currentUser.id, restaurantId },
    //   (err, { order }) => {
    //     dispatch({ type: "SET_SELECTED_ORDER", order });
    //     setPickupLoading(false);
    //   }
    // );
  };

  useEffect(() => {
    return () => {
      dispatch({ type: "SET_ORDER_ID", orderId: null });
      dispatch({ type: "SET_SELECTED_ORDER", order: null });
    };
  }, []);

  return (
    <div id="order-view">
      <div className="header">
        <Icon
          name="arrow left"
          onClick={() => {
            history.push(history.location.state?.backTo ?? "/orders");
          }}
        />
        <span>
          <span className="label">Commande </span>
          {selectedOrder && selectedOrder.number}
        </span>
      </div>

      <WithLoader loading={getOrder.loading}>
        {isDelivered ? (
          <>
            <div className="deliver-message">
              <Icon name="check" size="large" />
              <span>Commande livrée</span>
            </div>
            {deliveredId && (
              <Row justify="center">
                <Button
                  onClick={() => {
                    getOrder.request();
                    setIsDelivered(false);
                    setDeliveredId(null);
                  }}
                  color="blue"
                  basic
                >
                  Voir la commande
                </Button>
              </Row>
            )}
          </>
        ) : !selectedOrder ? (
          <NoData text="Commande non trouvée" centered />
        ) : selectedOrder.orderDelivery.status === "DELIVERED" ? (
          <CompletedOrder order={selectedOrder} />
        ) : (
          <Order
            order={selectedOrder}
            handleConfirmDelivery={handleConfirmDelivery}
            deliveryLoading={deliveryLoading}
            pickupLoading={pickupLoading}
            handlePickup={handlePickup}
          />
        )}
      </WithLoader>
    </div>
  );
};

export default OrderView;
