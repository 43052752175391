import { Header } from "semantic-ui-react";
import * as PriceHelper from "../../resources/helpers/PriceHelper";
import { Row, PaymentBadge, Price } from "../common";

const PaymentInformations = ({ paymentMethod, total }) => {
  return (
    <div className="payment-information">
      <Row>
        <Header style={{ marginBottom: "10px" }}>Moyen de paiement</Header>
      </Row>

      <Row justify="space-between">
        <PaymentBadge paymentMethod={paymentMethod} />

        <Price fontSize="1.4">
          {PriceHelper.renderCentimesAsAmount(total)}
        </Price>
      </Row>
    </div>
  );
};

export default PaymentInformations;
