import { useMemo } from "react";
import { formatTime, isLate } from "../../resources/helpers";
import { getOrderStatus } from "../../resources/constants/orderStatus";
import { Column, PaymentBadge } from "../common";

const PickupTime = ({ order, isCanceled }) => {
  const displayPickUpTime = useMemo(() => {
    return (
      getOrderStatus(order.orderDelivery.status).code !== "DELIVERING" &&
      !isCanceled &&
      order.restaurantOrders[0].estimatedPickupAt
    );
  }, [order.restaurantOrders, order.orderDelivery.status, isCanceled]);

  if (!displayPickUpTime) {
    return null;
  }

  return (
    <Column className="additional-info" childrenMargin="tiny" align="flex-end">
      <div
        className={`time-instruction${
          isLate(order.restaurantOrders[0].estimatedPickupAt) ? " is-late" : ""
        }`}
      >
        {formatTime(order.restaurantOrders[0].estimatedPickupAt)}
      </div>
      <PaymentBadge
        paymentMethod={order.paymentMethod}
        small
        style={{ marginRight: "4px" }}
      />
    </Column>
  );
};

export default PickupTime;
