import { Loader } from "semantic-ui-react";

const WithLoader = ({ loading, text, margin, modalMode, inverted, children }) => {
  return loading ? (
    <Loader
      active={loading}
      inverted={inverted}
      size="large"
      inline="centered"
      style={{ marginTop: margin ?? "2rem" }}
      className={modalMode ? "workaround-modal-loader" : ""}
    >
      {text}
    </Loader>
  ) : (
    children
  );
};

export default WithLoader;
