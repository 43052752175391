import Row from "./Row";

const NoData = ({ text, centered }) => {
  return (
    <Row className="no-data" justify={centered && "center"}>
      {text}
    </Row>
  );
};

export default NoData;
