import axios from "axios";
axios.defaults.withCredentials = true;
axios.defaults.baseURL =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_LOCAL_API_URL
    : process.env.REACT_APP_API_URL;

export async function fetchOrderById(orderId) {
  const { data } = await axios.get(`/api/delivery/orders/${orderId}`);

  return data;
}

export async function fetchOrders() {
  const { data } = await axios.get(`/api/delivery/orders`);

  return data;
}

export async function pickupRestaurantOrder({
  orderId,
  restaurantId,
}) {
  const { data } = await axios.post(
    `/api/delivery/orders/${orderId}/restaurants/${restaurantId}/pickup`
  );

  return data;
}

export async function fetchCompletedOrders({ startAt, endAt }) {
  const { data } = await axios.get(`/api/delivery/orders/completed`, {
    params: {
      startAt,
      endAt,
    },
  });

  return data;
}
