import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Icon, Label, Pagination } from "semantic-ui-react";
import { useRequest } from "../../resources/hooks";
import { fetchCompletedOrders } from "../../services/orders";
import { Column, Row, WithLoader, Divider, PaymentBadge } from "../common";

const OFFSET_TOP = 100; // px
const ORDER_ROW_HEIGHT = 70; // px
const maxOrdersPerPage = Math.floor(
  (window.innerHeight - OFFSET_TOP) / ORDER_ROW_HEIGHT
);

const CompletedOrders = () => {
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const getCompletedOrders = useRequest({
    service: fetchCompletedOrders,
    fetchOnInit: false,
  });

  useEffect(() => {
    handlePageChange(1);
  }, []);

  const maxPage =
    Math.ceil(getCompletedOrders.response?.count / maxOrdersPerPage) || 1;

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    getCompletedOrders.request({
      params: {
        startAt: (newPage - 1) * maxOrdersPerPage,
        endAt: newPage * maxOrdersPerPage,
      },
    });
  };

  const completedOrders = getCompletedOrders.response?.orders || [];

  return (
    <div id="completed-orders-overview">
      <div className="header">
        <Icon
          name="arrow left"
          onClick={() => {
            history.push("/");
          }}
        />
        <span>Commandes terminées</span>
      </div>

      <WithLoader
        loading={getCompletedOrders.loading}
        text="Récupération des commandes"
      >
        <Pagination
          className="pagination"
          boundaryRange={0}
          onPageChange={(e, { activePage }) => handlePageChange(activePage)}
          activePage={currentPage}
          siblingRange={1}
          totalPages={maxPage}
          pointing
          secondary
          fluid
        />
        {completedOrders.map((order) => (
          <div
            onClick={() =>
              history.push(`/orders/${order.id}`, {
                backTo: "/orders/completed",
              })
            }
          >
            <Row className="order-row" childrenMargin="small">
              <span className="number">{order.number}</span>

              <Column>
                <span className="customer">{`${order.customer.firstname} ${order.customer.lastname}`}</span>
                <span className="payment-method">
                  <PaymentBadge paymentMethod={order.paymentMethod} />
                </span>
              </Column>

              <Column justify="center" style={{ marginLeft: "auto" }}>
                <Label
                  color={
                    order.orderDelivery.status === "DELIVERED" ? "green" : "red"
                  }
                  className="status"
                  basic
                >
                  {order.orderDelivery.status === "DELIVERED"
                    ? "Livrée"
                    : "Annulée"}
                </Label>
              </Column>
            </Row>
            <Divider noMargin />
          </div>
        ))}
      </WithLoader>
    </div>
  );
};

export default CompletedOrders;
