import "../../styles/common.scss";

const Column = ({
  children,
  className,
  justify,
  align,
  fluid,
  style,
  childrenMargin,
  onClick,
}) => (
  <div
    className={`column ${className ?? ""}${
      childrenMargin ? ` children-margin ${childrenMargin}` : ""
    }`}
    style={{
      justifyContent: justify,
      alignItems: align,
      width: fluid && "100%",
      ...style,
    }}
    onClick={onClick}
  >
    {children}
  </div>
);

export default Column;
