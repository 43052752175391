import { useContext } from "react";
import { Header, Button, Loader } from "semantic-ui-react";
import { useRequest, useInterval } from "../../resources/hooks";
import { getVerificationCode, getSession } from "../../services/auth";
import { VERIFICATION_CODE_INTERVAL } from "../../resources/constants";
import { AuthContext } from "../../contexts/auth";
import "../../styles/Auth.scss";

const UnlinkedAccount = ({ currentUser, disconnect }) => {
  const { dispatch } = useContext(AuthContext);

  const verificationCode = useRequest({ service: getVerificationCode });

  const refreshUser = useRequest({
    service: getSession,
    requestOnInit: false,
  });

  useInterval(async () => {
    const { response: refreshedUser } = await refreshUser.request();

    if (refreshedUser?.psid) {
      dispatch({ type: "SET_USER", user: refreshedUser });
    }
  }, VERIFICATION_CODE_INTERVAL * 1000);

  return (
    <>
      <div id="messenger-warning">
        <Header inverted>Rattachement compte Messenger</Header>
        <p>
          {">"} Accèdez à
          <a
            // production
            href="https://www.messenger.com/t/106174092043620"
            // dev
            // href="https://www.messenger.com/t/103021811927246"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            ce lien
          </a>
        </p>
        <p>{">"} Donnez le code</p>

        <p style={{ margin: "3rem" }}>
          {verificationCode.loading ? (
            <Loader active inverted size="large" inline="centered" />
          ) : (
            <span className="verification-code">
              {verificationCode.response}
            </span>
          )}
        </p>

        <Button
          negative
          fluid
          basic
          style={{ marginTop: "8rem" }}
          onClick={disconnect}
        >
          Déconnexion
        </Button>
      </div>
    </>
  );
};

export default UnlinkedAccount;
