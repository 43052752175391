import { Header, Message, Icon } from "semantic-ui-react";
import { Column, Row } from "../common";
import { formatTime, isLate } from "../../resources/helpers";
import { getOrderStatus } from "../../resources/constants/orderStatus";

const DeliveryInformations = ({
  estimatedPickupAt,
  estimatedDeliveryAt,
  comments,
  status,
}) => {
  return (
    <Column className="delivery-information">
      <Row>
        <Header style={{ margin: 0 }}>Informations livraison</Header>
      </Row>

      <Row margin="5px 0" align="center" justify="space-between">
        Récupération prévue à{" "}
        <time
          className={`time${isLate(estimatedPickupAt) ? " is-late" : ""}${
            getOrderStatus(status).code !== "assigned" ? " disabled" : ""
          }`}
        >
          {formatTime(estimatedPickupAt)}
        </time>
      </Row>
      <Row margin="5px 0" align="center" justify="space-between">
        Livraison prévue à{" "}
        <time
          className={`time${isLate(estimatedDeliveryAt) ? " is-late" : ""}`}
        >
          {formatTime(estimatedDeliveryAt)}
        </time>
      </Row>

      {comments && (
        <Message warning icon>
          <Icon name="talk" />
          <Message.Content>{comments}</Message.Content>
        </Message>
      )}
    </Column>
  );
};

export default DeliveryInformations;
