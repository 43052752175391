import { useState } from "react";
import { Accordion, Button, Header, Icon } from "semantic-ui-react";
import { orderStatus } from "../../resources/constants/orderStatus";
// import { formatTime } from "../../resources/helpers";
import { Column, Row, OrderStatus } from "../common";
import OrderProducts from "../common/OrderProducts";

const RestaurantProducts = ({
  restaurantId,
  restaurantLabel,
  products: rawProducts,
  withStatus,
  status,
  estimatedPickupAt,
  pickupAt,
  pickupLoading,
  handlePickup,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [confirmId, setConfirmId] = useState(null);
  const products = rawProducts.filter(
    (raw) => raw.originalRestaurant.id === restaurantId
  );

  return (
    <div>
      <Accordion>
        <Accordion.Title
          active={isOpen}
          index={0}
          onClick={() => setIsOpen(!isOpen)}
          className="restaurant-title"
        >
          {withStatus ? (
            <Row align="center">
              <Icon name="dropdown" />
              <Row fluid justify="space-between" align="center">
                <Column>
                  <Header style={{ marginBottom: "5px" }}>
                    {restaurantLabel}
                  </Header>
                  <OrderStatus status={status} />
                  {/* <span>
                    {status === orderStatus.delivering.code
                      ? `Récupérée à ${formatTime(pickupAt)}`
                      : `Prête pour ${formatTime(estimatedPickupAt)}`}
                  </span> */}
                </Column>

                <Column>
                  {(status === orderStatus.READY.code ||
                    status === orderStatus.PREPARATION.code) &&
                    (confirmId === restaurantId ? (
                      <Button
                        color="green"
                        loading={pickupLoading}
                        disabled={pickupLoading}
                        onClick={(e) => {
                          e.stopPropagation();
                          setConfirmId(null);
                          handlePickup(restaurantId);
                        }}
                      >
                        Confirmer ?
                      </Button>
                    ) : (
                      <Button
                        color="green"
                        loading={pickupLoading}
                        disabled={pickupLoading}
                        onClick={(e) => {
                          e.stopPropagation();
                          setConfirmId(restaurantId);
                          setTimeout(() => setConfirmId(null), 3000);
                        }}
                      >
                        Récupérée
                      </Button>
                    ))}
                </Column>
              </Row>
            </Row>
          ) : (
            <>
              <Icon name="dropdown" />
              {restaurantLabel}
            </>
          )}
        </Accordion.Title>

        <Accordion.Content active={isOpen} className="accordion-content">
          <Column childrenMargin="tiny">
            <OrderProducts products={products} />
          </Column>
        </Accordion.Content>
      </Accordion>
    </div>
  );
};

export default RestaurantProducts;
