import io from "socket.io-client";
import cookies from "js-cookie";
export let socket;

const ENDPOINT =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_LOCAL_API_URL
    : process.env.REACT_APP_API_URL;

export const initiateSocket = (userId, cb) => {
  socket = io(ENDPOINT, {
    auth: {
      token: cookies.get("token"),
    },
  });

  if (socket) {
    socket.emit("/delivery/identify", { userId }, (response) => {
      return cb(null, response);
    });
  }
};

export const disconnectSocket = () => {
  if (socket) {
    socket.removeAllListeners();
    socket.disconnect();
  }
};

export const subscribeToOrders = (cb) => {
  if (!socket) return true;
  socket.on("/orders/update", (msg) => {
    return cb(null, msg);
  });
};

export const subscribeToAssignedOrders = (cb) => {
  if (!socket) return true;
  socket.on("/orders/new", (msg) => {
    return cb(null, msg);
  });
};

export const subscribeToOrdersUpdates = (cb) => {
  if (!socket) return true;
  socket.on("/orders/update", (msg) => {
    return cb(null, msg);
  });
};

export const subscribeToCanceledOrders = (cb) => {
  if (!socket) return true;
  socket.on("/orders/cancel", (msg) => {
    return cb(null, msg);
  });
};

export const pickupOrder = ({ orderId, deliverymanId, restaurantId }, cb) => {
  if (!socket) return true;
  socket.emit(
    "/delivery/order/pickup",
    { orderId, deliverymanId, restaurantId },
    (msg) => {
      return cb(null, msg);
    }
  );
};

export const confirmOrder = ({ orderId, deliverymanId }, cb) => {
  if (!socket) return true;
  socket.emit(
    "/delivery/order/acknowledge",
    { orderId, deliverymanId },
    (msg) => {
      return cb(null, msg);
    }
  );
};

export const confirmDelivery = ({ orderId, deliverymanId }, cb) => {
  if (!socket) return true;
  socket.emit("/delivery/order/deliver", { orderId, deliverymanId }, (msg) => {
    return cb(null, msg);
  });
};

export const fetchOrders = (cb, deliverymanId) => {
  if (!socket) return true;
  socket.emit("/delivery/orders/refresh", { deliverymanId }, (payload) => {
    return cb(null, payload);
  });
};

export const handleDisconnection = (callback) => {
  if (!socket) return;
  socket.on("disconnect", (reason) => {
    return callback(reason);
  });
};

export const handleReconnection = ({ userId }, callback) => {
  if (!socket) return;

  socket.io.on("reconnect", (reconnectionAttemptNumber) => {
    
    socket.emit("/delivery/identify", { userId }, (response) => {});

    callback(reconnectionAttemptNumber);
  });
};
