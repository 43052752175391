import { useCallback, useEffect, useRef, useState } from "react";

function useInterval(callback, delay) {
  const savedCallback = useRef();
  const intervalId = useRef(null);
  const [currentDelay, setDelay] = useState(delay);

  const toggle = useCallback(
    () => setDelay((currentDelay) => (currentDelay === null ? delay : null)),
    [delay]
  );

  const clear = useCallback(() => clearInterval(intervalId.current), []);

  const reset = useCallback(() => {
    if (intervalId.current !== null) {
      clearInterval(intervalId.current);
      setDelay(delay);
      intervalId.current = setInterval(savedCallback.current, delay);
    }
  }, [delay]);

  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (intervalId.current) clear();

    if (currentDelay !== null) {
      intervalId.current = setInterval(tick, currentDelay);
    }

    return clear;
  }, [currentDelay, clear]);

  return { reset, toggle, clear, running: !!currentDelay };
}

export default useInterval;
